
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FormInputState } from '@/models/Forms/FormState';

@Component<ZipCodeInputComponent>({})
export default class ZipCodeInputComponent extends Vue {
  @Prop()
  readonly id?: string;

  @Prop()
  readonly value?: number;

  @Prop()
  readonly label?: string;

  @Prop()
  readonly placeholder?: string;

  private zip = '';

  @Prop({ default: 'Please enter a valid zip code' })
  readonly errorText?: string;

  @Prop({ default: false })
  readonly required?: boolean;

  @Prop({ default: false })
  readonly readonly!: boolean;

  private regex?: RegExp = new RegExp('^\\d{5}(-\\d{4})?$');
  private isPristine = true;

  get showError() {
    return !this.isPristine && !this.isValid;
  }

  get isValid() {
    if ((!this.required && this.zip === '') || !this.regex) return true;
    return this.regex.test(this.zip);
  }

  private updateValue(value: string) {
    const numberString = value.replace(/-/g, '');
    // Get formatted number string
    const zipString = this.formatZipByNumString(numberString);
    // Set display value
    this.zip = zipString;
    // Emit number value
    this.$emit('input', zipString);
  }

  created() {
    this.setZip();
    this.emitValidationState();
  }

  @Watch('isValid')
  private emitValidationState() {
    this.$emit(
      'validationState',
      new FormInputState({
        isValid: this.isValid,
        errorMessage: this.errorText
      })
    );
  }

  @Watch('value')
  private setZip() {
    if (this.value) {
      this.zip = this.formatZipByNumString(this.value.toString());
      this.updateValue(this.zip);
    }
  }

  private formatZipByNumString(value: string) {
    const numString = value.toString();
    if (numString.length > 5) {
      return numString.slice(0, 5) + '-' + numString.slice(5);
    }
    return numString;
  }

  private getZipCode(value: string): number {
    const numberString = value.replace(/-/g, '');
    return Number(numberString);
  }

  onBlur() {
    this.isPristine = false;
    this.$emit('blur');
  }
}
