
import {
  Component,
  Emit,
  InjectReactive,
  Mixins,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator';
import SnackbarMixin from '@/mixins/snackbarMixin';
import ProfileDetailsBaseComponent from './ProfileDetailsBaseComponent.vue';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import TextInputComponent from '@/components/Forms/Elements/TextInputComponent.vue';
import DropdownComponent from '../../../components/Forms/Elements/DropdownComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import { EEthnicity, EGender, ERace, EStates, EUserRoles } from '@/enums';
import SocialSecurity from '@/components/Forms/Elements/SocialSecurityComponent.vue';
import DateSelectorComponent from '@/components/Forms/Elements/DateSelectorComponent.vue';
import CheckboxComponent from '../../../components/Forms/Elements/CheckboxComponent.vue';
import { EContactMethods } from '@/models/Entities/Personas/profileInformation/types';
import StickyContainerComponent from '@/components/StickyContainerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import NumberInputComponent from '../../../components/Forms/Elements/NumberInputComponent.vue';
import { yesNoOptions } from '@/models/Forms/FormOption';
import RadioComponent from '@/components/Forms/Elements/RadioComponent.vue';
import PhoneInput from '@/components/Forms/Elements/PhoneInputComponent.vue';
import { EPropertyType } from '@/pages/Applicant/constants';
import { EDegreeTypes } from '@/enums/EDegreeTypes';
import ZipCodeInputComponent from '../../../components/Forms/Elements/ZipCodeInputComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import ProfilePageModel, {
  PROFILE_PAGE_SECTION_LABELS
} from '@/models/Profile/ProfilePageModel';
import generateScrollToCallback from '@/util/generateScrollToCallback';
import {
  ApplicantTypeNestedDropDownOption,
  RecruitmentStatus
} from '@/models/Entities/Personas/Constants';
import DropdownNestedComponent from '@/components/Forms/Elements/DropdownNestedComponent.vue';

@Component<ProfilePage>({
  components: {
    ProfileDetailsBaseComponent,
    TextInputComponent,
    DropdownComponent,
    SocialSecurity,
    DateSelectorComponent,
    CheckboxComponent,
    StickyContainerComponent,
    ButtonComponent,
    NumberInputComponent,
    RadioComponent,
    PhoneInput,
    ZipCodeInputComponent,
    IconComponent,
    DropdownNestedComponent
  }
})
export default class ProfilePage extends Mixins(Vue, SnackbarMixin) {
  @Prop({ required: true })
  applicant!: Applicant;

  model = new ProfilePageModel();

  sexOptions = getInputOptions(EGender);
  raceOptions = getInputOptions(ERace);
  ethnicityOptions = getInputOptions(EEthnicity);
  preferredcontactMethodOptions = getInputOptions(EContactMethods);
  yesNoOptions = yesNoOptions;
  propertyOptions = getInputOptions(EPropertyType);
  degreeDropdownOptions = getInputOptions(EDegreeTypes);
  applicantTypeNestedDropDownOptions = ApplicantTypeNestedDropDownOption;
  recruitmentStatusOptions = getInputOptions(RecruitmentStatus);
  stateDropdownOptions = getInputOptions(EStates);

  sections = PROFILE_PAGE_SECTION_LABELS;

  @InjectReactive('isUpdating')
  isUpdating!: boolean;

  created(): void {
    this.model = new ProfilePageModel(this.applicant);
  }

  @Watch('model')
  @Watch('applicant')
  setSectionStatus(): void {
    const {
      APPLICANT_TYPE,
      PERSONAL_INFORMATION,
      ADDRESS,
      EDUCATION,
      ADDITIONAL_INFORMATION
    } = this.sections;

    this.$store.dispatch('profileSideNavigationModule/setSections', [
      {
        header: 'Profile',
        entries: [
          {
            text: APPLICANT_TYPE.label,
            status: this.model.applicantTypeSectionStatus,
            onClick: generateScrollToCallback(APPLICANT_TYPE.key)
          },
          {
            text: PERSONAL_INFORMATION.label,
            status: this.model.personalInformationSectionStatus,
            onClick: generateScrollToCallback(PERSONAL_INFORMATION.key)
          },
          {
            text: ADDRESS.label,
            status: this.model.addressSectionStatus,
            onClick: generateScrollToCallback(ADDRESS.key)
          },
          {
            text: EDUCATION.label,
            status: this.model.educationSectionStatus,
            onClick: generateScrollToCallback(EDUCATION.key)
          },
          {
            text: ADDITIONAL_INFORMATION.label,
            status: this.model.addtionalInformationSection,
            onClick: generateScrollToCallback(ADDITIONAL_INFORMATION.key)
          }
        ]
      }
    ]);
  }

  get canEditRecruitmentStatus(): boolean {
    const allowedRoles = [
      EUserRoles.ADMIN,
      EUserRoles.A3_SR_LEADER,
      EUserRoles.THREE_40TH_FTG_SENIOR_MEMBER,
      EUserRoles.THREE_40TH_FTG_JUNIOR_MEMBER
    ];
    return allowedRoles.includes(
      this.$store.getters['userModule/currentUser'].role
    );
  }

  @Emit('updateApplicant')
  submitForm(): Applicant {
    return this.model;
  }
}
