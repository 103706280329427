import { ESectionStatus } from '@/enums/ESectionStatus';
import { BaseCandidate } from '../Entities/BaseCandidate';
import { convertToKeyValueObj } from '@/util/object/convertToKeyValueObj';
import { getSectionStatus } from '@/util/getSectionStatus';

export const PROFILE_PAGE_SECTION_LABELS = convertToKeyValueObj({
  APPLICANT_TYPE: 'Applicant Type',
  PERSONAL_INFORMATION: 'Personal Information',
  ADDRESS: 'Address',
  EDUCATION: 'Academic Information',
  ADDITIONAL_INFORMATION: 'Additional Information'
});

export default class ProfilePageModel extends BaseCandidate {
  statusFunction: (fields: unknown[]) => ESectionStatus;

  get applicantTypeSectionStatus(): ESectionStatus {
    return getSectionStatus([this.applicantType]);
  }

  get personalInformationSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.identificationInformationFirstname,
      this.identificationInformationLastname,
      this.demographicsGender,
      this.demographicsRace,
      this.identificationInformationDateofbirth,
      this.identificationInformationSocialsecuritynumber,
      this.contactInformationEmail,
      this.contactInformationPreferredcontactmethod
    ]);
  }

  get addressSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.contactInformationAddress1,
      this.contactInformationState,
      this.contactInformationZipcode,
      this.housingInformationPropertytype,
      this.housingInformationLeaseexpirationdate
    ]);
  }

  get educationSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.academicInformationInstitution,
      this.academicInformationDegree,
      this.academicInformationGraduationdate,
      this.academicInformationGpa
    ]);
  }

  get addtionalInformationSection(): ESectionStatus {
    return this.statusFunction([
      this.interestedInSpecialWarfare,
      this.isSingleParent,
      this.hasBeenConvictedOfAFelony,
      this.hasUsedIllegalDrugs,
      this.hasCovidVaccine
    ]);
  }
  constructor(
    partial?: Partial<BaseCandidate>,
    statusFunction = getSectionStatus
  ) {
    super(partial);
    this.statusFunction = statusFunction;
  }
}
